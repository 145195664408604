export const EnrolmentSidebarLink = [
  {
    name: "Enrolments",
    slug: "enrolment",
  },
  {
    name: "Schedule Of Fees",
    slug: "scheduleoffees",
  },
  {
    name: "Scholarships",
    slug: "scholarships",
  },
  {
    name: "International Students",
    slug: "international",
  },
];

export const about = [
  {
    name: "Our History",
    slug: "about/history",
  },
  {
    name: "From the Principal",
    slug: "about/fromtheprincipal",
  },
  {
    name: "Mission, Vision & Values",
    slug: "about/mission",
  },
  {
    name: "Annual Report",
    slug: "about/annualreport",
  },
  {
    name: "Policies",
    slug: "about/policies",
  },
  {
    name: "Employment",
    slug: "about/employment",
  },
];

export const educational = [
  {
    name: "Primary Years",
    slug: "education/primaryyear",
  },
  {
    name: "Secondary Years",
    slug: "education/secondaryyear",
  },
  {
    name: "Pastoral Care",
    slug: "education/pastoralcare",
  },
  {
    name: "HSC Results 2022",
    slug: "education/hscresults2022",
  },
];
export const community = [
  // {
  //   name: "Primary Years",
  //   slug: "education/primaryyear",
  // },
  // {
  //   name: "Secondary Years",
  //   slug: "education/secondaryyear",
  // },
  // {
  //   name: "Pastoral Care",
  //   slug: "education/pastoralcare",
  // },
];
