import React from "react";
import { about } from "../const";
import ContentWrapper from "../master/ContentWrapper";
import PageTitle from "../master/PageTittle";
import SideBar from "../master/SideBar";
const HistoryContent = () => (
  <div className="container">
    <ContentWrapper sidebar={true} narrow={true}>
      <div className={"content"}>
        <PageTitle title="Our History" />

        <PageTitle
          title="Charism of the Maronite Sisters of the Holy Family (MSHF)"
          variant="h3"
        />

        <p>
          The first female Maronite Congregation, The Maronite Sisters of the
          Holy Family, was founded by Patriarch Elias Howayek, Rosalie Nasr and
          Stéphanie Kardouche, on 15th August 1895. Historically the Maronite
          Sisters of the Holy Family was ‘founded for’ the ministry of educating
          village girls. The Congregation from the very beginning was focused on
          the needs of the times, committed to educational, humanitarian and
          spiritual service.
        </p>

        <p>
          The Congregation is deep-rooted in the Antioch Syriac Maronite Church
          and is open to the universal Church. It perceives the needs of the
          Lebanese and Expansion while serving all, in the Body of Christ. The
          wave of immigrants from Lebanon hit Australian shores, beginning in
          the late 1800s and steadily increased in the latter half of the 1900s.
          Many later Maronite migrants wanted to preserve their Maronite faith
          and tradition. As a result, the Sisters were sent to Australia in
          1968, settling in Sydney, and have since then established two K-12
          Colleges, a child-care, and a pre-school as well as two aged care
          facilities.
        </p>

        <PageTitle title="Saint Maroun’s College" variant="h3" />

        <p>
          The history of Saint Maroun’s College began in 1968 when the Maronite
          Sisters of the Holy Family arrived in Australia at the invitation of
          the head of the Maronite Church in Australia, Monsignor Ziade of St
          Maroun’s Cathedral in Redfern, New South Wales.
        </p>

        <p>
          Renowned for their teaching methods that respect the individual’s
          right to meet their full academic potential whilst preserving their
          language and knowledge of their cultural identity, the Maronite
          Sisters immediately set about to establish an educational facility
          that could meet the cultural and academic needs of the Maronite
          migrant children in Australia. With the commitment of the Maronite
          Community and the support of the New South Wales Department of
          Education, the Maronite Sisters in 1970 opened their first Maronite
          Primary School in Australia with Sr Marie Henriette as its first
          Principal. Saint Maroun’s College would be located in the grounds of
          the Cathedral at Redfern, until its relocation in 1989, to the current
          site at Dulwich Hill. The purchase of the current site from the
          Carmelite order of Nuns meant that the growing needs for a culturally
          specific education of the children from the Maronite community could
          be met. The new site covered almost eight acres of land and could not
          only accommodate the Primary School from Redfern but also a
          Pre-school, which was established in the same year. The existing
          content on the site also meant that the Maronite Sisters of the Holy
          Family now had a permanent home.
        </p>

        <p>
          {" "}
          The growing demand by the parents for continued education in a
          culturally and linguistically adapted learning environment meant that
          the first group of Year 6 students progressed to Year 7 in 1998 who
          continued as the first stream of Secondary Students to graduate from
          Year 12 in 2003. What seemingly was a culturally specific purpose of
          establishing the College is now a multicultural approach to preserving
          the unique educational methodology under the Maronite Sisters of the
          Holy Family. Saint Maroun’s College today is proud to have students
          from many different nationalities who work and learn together in an
          environment of harmony, tolerance, and love and where they are
          encouraged to compete academically to achieve at the highest
          educational level. The success of Saint Maroun’s College today owes
          much to its Principals – Sr Marie Henriette, Sr Madeleine de la Croix,
          Sr Irene Boughosn, Sr Josephine Wehbe and currently Sr Margaret Ghosn.
        </p>

        <p>Faith, Friendship and Honesty</p>
      </div>
      <SideBar items={about} title="About Us" />
    </ContentWrapper>
  </div>
);

export default HistoryContent;
