import * as React from "react";
import Layout from "../../components/layout";
import HistoryContent from "../../components/about/historyContent";
import BannerImage from "../../components/master/BannerImage";
import heroImage from "../../assets/about/sisters-hero.jpg";

const HistoryPage = () => {
  return (
    <Layout>
      <BannerImage imagePath={heroImage} altText="our history" />
      <HistoryContent />
    </Layout>
  );
};

export default HistoryPage;
